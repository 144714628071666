import axios from "axios";

const service = axios.create({
  // baseURL: "http://192.168.1.222:10001",
  baseURL: "https://api.showings.cn",
  timeout: 5000,
});

// 请求拦截器
service.interceptors.request.use(
  function (config) {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response) {
      let data = error.response.data;
      return Promise.reject(data.msg);
    }
    return Promise.reject('网络错误')
  }
);

export function get(url, params) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    service({
      method: "post",
      url: url,
      data: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default service;
